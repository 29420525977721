import React, { ReactElement } from "react";
import MainBanner from "@components/nomoredebttoday/mainBanner/mainBanner";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import NoMoreDebtTodayWrapper from "@components/layouts/noMoreDebtTodayWrapper";
import OffersList from "@components/shared/offersList";
import WhySeekDebtRelief from "@components/nomoredebttoday/whySeekDebtRelief";
import DebtReliefProcessLike from "@components/nomoredebttoday/DebtReliefProcessLike";
import GoodDebtReliefCompany from "@components/nomoredebttoday/goodDebtReliefCompany";
import DebtReliefCompanyBenefit from "@components/nomoredebttoday/debtReliefCompanyBenefit";
export default function DebtReliefCornerHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <NoMoreDebtTodayWrapper>
            <MainBanner />
            <section style={{ padding: "0 15px" }}>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhySeekDebtRelief />
            <DebtReliefProcessLike />
            <GoodDebtReliefCompany />
            <DebtReliefCompanyBenefit />
        </NoMoreDebtTodayWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
